<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="20"
    class="sm:mx-6 mt-6 py-6 px-6 sm:px-16"
  >
    <TokenCreateModal v-if="isOpenCreateModal" @close="closeCreateModal" />

    <AssetTitle v-if="user.email !== ''" title="My NFTs" />

    <div class="flex justify-between items-center mb-8">
      <div>
        <t-filter-select
          class="w-45 inline-block"
          placeholder="All NFTs"
          :options="[
            { value: 'CREATED', label: 'Drafts' },
            { value: 'FOR_SALE', label: 'On the market' },
            { value: 'SOLD', label: 'Bought' },
          ]"
          @change="filterTokensByState"
        />
        <button
          class="uppercase border-b-2 border-transparent hover:border-gray-300 hover:text-gray-600 inline-block ml-3.5 sm:ml-12"
          @click="resetFilters"
        >
          reset
        </button>
      </div>
      <t-button
        :disabled="user.state !== 'KYC_PASS'"
        class="w-60"
        @click="openCreateModal"
      >
        Create NTF
      </t-button>
    </div>
    <div class="flex flex-wrap justify-center">
      <TokenListCard
        v-for="token in tokens"
        :key="token.id"
        :token="token"
        :show-status="true"
      />
    </div>
    <Loader v-if="isLoading" :classes="'w-24 h-24 mx-auto'" />

    <h4
      v-else-if="tokens.length === 0 && filters.state"
      class="text-24 text-center pt-8"
    >
      You have no NFTs that matches criteria!
    </h4>

    <h4 v-else-if="tokens.length === 0" class="text-24 text-center pt-8">
      You have no NFTs yet.
    </h4>
  </div>
</template>

<script>
import * as api from '@/api';
import AssetTitle from '@/components/AssetTitle';
import TokenCreateModal from '@/components/modals/TokenCreateModal';
import TokenListCard from '@/components/cards/TokenListCard';
import Loader from '@/components/Loader';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    TokenCreateModal,
    AssetTitle,
    TokenListCard,
    Loader,
  },
  data() {
    return {
      tokens: [],
      busy: false,
      lastPage: 0,
      hasMore: true,
      isLoading: false,
      isOpenCreateModal: false,
      filters: {
        state: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    this.getUser();
    this.loadMore();
  },
  methods: {
    ...mapActions({
      getUser: 'user/GET_USER_DATA',
    }),
    cleanTokens() {
      this.tokens = [];
      this.busy = false;
      this.lastPage = 0;
      this.hasMore = true;
      this.isLoading = false;
    },
    async loadMore() {
      // pagination
      if (this.busy || !this.hasMore) return;
      this.busy = true;
      this.isLoading = true;

      const { count, results } = await api.get('tokens', {
        page: this.lastPage + 1,
        my_nfts: true,
        state: this.filters.state,
      });

      this.tokens = this.tokens.concat(results);
      this.hasMore = count > this.tokens.length;
      this.isLoading = false;

      this.busy = false;
      this.lastPage++;
    },
    openCreateModal() {
      this.isOpenCreateModal = true;
    },
    closeCreateModal() {
      this.isOpenCreateModal = false;
    },
    resetFilters() {
      this.filters.state = '';
      this.cleanTokens();
      this.loadMore();
    },
    filterTokensByState(event) {
      const value = event.target?.value;
      if (value) {
        this.filters.state = value;
        this.cleanTokens();
        this.loadMore();
      }
    },
  },
};
</script>
